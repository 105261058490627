import React from 'react'
import './Advantages.css'
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from 'swiper/modules';
import "swiper/css";
import ImageGroup1 from '../../assets/Images/Group(1).png'
import ImageFrame6 from '../../assets/Images/Frame(6).png'
import ImageGroup3 from '../../assets/Images/Group(3).png'
import ImageFrame7 from '../../assets/Images/Frame(7).png'
import Image from '../..'

function Advantages() {



  return (
    <article className='Advantages'>
          <h1 className='Advantages-title'>Наши преимущества</h1>
          <div className='Advanteges-block'>
        <div className='Advanteges-blocks'>
         <img src={ImageGroup1} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>45 мин</p>
         <p className='Advanteges-block-text'>Среднее время подачи курьера</p>
        </div>
        <div className='Advanteges-blocks'>
         <img src={ImageFrame6} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>от 150 с</p>
         <p className='Advanteges-block-text'>от 150 сом за каждый адрес</p>
        </div>
        <div className='Advanteges-blocks'>
         <img src={ImageGroup3} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>100%</p>
         <p className='Advanteges-block-text'>100% компенсация утери или повреждения за 0.9% от ценности отправления</p>
        </div>
        <div className='Advanteges-blocks'>
         <img src={ImageFrame7} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>10%</p>
         <p className='Advanteges-block-text'>При заказе от 5 точек</p>
        </div>
       </div>
       <div className='Swiper'>
       <Swiper
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
       className="mySwiper">
        <SwiperSlide>
        <div className='Advanteges-blocks'>
         <img src={ImageGroup1} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>45 мин</p>
         <p className='Advanteges-block-text'>Среднее время подачи курьера</p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='Advanteges-blocks'>
         <img src={ImageFrame6} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>от 150 с</p>
         <p className='Advanteges-block-text'>от 150 сом за каждый адрес</p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='Advanteges-blocks'>
         <img src={ImageGroup3} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>100%</p>
         <p className='Advanteges-block-text'>100% компенсация утери или повреждения за 0.9% от ценности отправления</p>
        </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='Advanteges-blocks'>
         <img src={ImageFrame7} alt="" className='Image'/>
         <p className='Advanteges-block-bigtext'>10%</p>
         <p className='Advanteges-block-text'>При заказе от 5 точек</p>
        </div>
        </SwiperSlide>
      </Swiper>
      </div>
    </article>
  )
}

export default Advantages