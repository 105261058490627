import React from 'react'
import './Contentblock.css'
import ImagePic from '../../assets/Images/79095552.png'
import ImageLine from '../../assets/Images/Group6934.png'
import ImagePic1 from '../../assets/Images/g58.png'
import ImageLine2 from '../../assets/Images/Group69341.png'
import ImageFrame from '../../assets/Images/Frame1000004123(1).png'

function Contentblock() {
  return (
    <article className='Content-block'>
          <h1 className='Content-block-title'>Как это работает?</h1>
          <div className='Content-block-Image'>
           <div className='block-Image-text'>
            <div className='block-Image-title'>
             <p className='Numbers'>1</p>
             <p className='block-Image-name'>Заполните заявку</p>
            </div>
            <p className='block-Image-desc'>100% компенсация утери или повреждения за 0.9% от ценности отправления</p>
           </div>
            <img src={ImagePic} alt="" className='Pic' />
          </div>
          <img src={ImageLine} alt="" className='Line'/>
          <div className='Content-block-Image' id='Image2'>
           <div className='block-Image-text'>
            <div className='block-Image-title'>
             <p className='Numbers'>2</p>
             <p className='block-Image-name'>Ожидайте звонка оператора</p>
            </div>
            <p className='block-Image-desc'>100% компенсация утери или повреждения за 0.9% от ценности отправления</p>
           </div>
            <img src={ImagePic1} alt="" className='Pic'/>
          </div>
          <img src={ImageLine2} alt="" className='Line' id='Line2'/>
          <div className='Content-block-Image'>
           <div className='block-Image-text'>
            <div className='block-Image-title'>
             <p className='Numbers'>3</p>
             <p className='block-Image-name'>Ожидайте курьера</p>
            </div>
            <p className='block-Image-desc'>100% компенсация утери или повреждения за 0.9% от ценности отправления</p>
           </div>
            <img src={ImageFrame} alt="" className='Pic' />
          </div>
        </article>
  )
}

export default Contentblock