import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay} from 'swiper/modules';
import 'swiper/css';
import './Coop.css'
import ImageFrame1 from '../../assets/Images/Frame1000004142.png'
import ImageLink from '../../assets/Images/Link-608002ff4adc8.jpg(1).png'
import ImageLink1 from '../../assets/Images/Link-5ef2f6d168c2d.png(1).png'

function Coop() {
  return (
    <article className='Cooperation'>
            <h1 className='Cooperation-title'>С нами сотрудничают:</h1>
            <div className='Cooperation-blocks'>
            <div className='Cooperation-block'>
             <img src={ImageFrame1} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
            <div className='Cooperation-block'>
             <img src={ImageLink} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
            <div className='Cooperation-block'>
             <img src={ImageLink1} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
            <div className='Cooperation-block'>
             <img src={ImageLink1} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
            </div>
            <div className='Slider'>
            <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className='Cooperation-block'>
             <img src={ImageFrame1} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='Cooperation-block'>
             <img src={ImageLink} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='Cooperation-block'>
             <img src={ImageLink1} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div>
        </SwiperSlide>
        <SwiperSlide>
        <div className='Cooperation-block'>
             <img src={ImageLink1} alt="" className='Img'/>
             <p className='Cooperation-years'>10 лет</p>
             <p className='Cooperation-text'>“Share the full value of total compensation including salary, equity, retirement, health plans, and perks to candidates.”</p>
            </div> 
        </SwiperSlide>
      </Swiper>
      </div>
          </article>
  )
}

export default Coop