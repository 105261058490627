import React from 'react'
import './Footer.css'
import ImageFill from '../../assets/Images/Fill.png'
import ImageRec5 from '../../assets/Images/Rectangle(5).png'
import ImageRec2 from '../../assets/Images/Rectangle(2).png'
import ImageFrame8 from '../../assets/Images/Frame(8).png'
import ImageLogo from '../../assets/Images/Frame(5).png'

function Footer({contactRef}) {
  return (
    <footer ref={contactRef}>
      <input type='checkbox' id='footer-check' />
      <input type='checkbox' id='footer-check2' />
      <div className='footer-logo'>
        <img src={ImageLogo} alt="" className='footer-logo-img' />
        <div className='icon-conteiner'>
          <a href="https://wa.me/996706973080"  target="_blank"  rel="noopener noreferrer">
              <img src={ImageFill} alt="" className='footer-Img'  />
          </a>
          <a href="https://www.instagram.com/zakaz.kg"  target="_blank"  rel="noopener noreferrer">
            <img src={ImageRec5} alt="" className='footer-Img'/>
          </a>
          <a href="https://www.facebook.com/zakaz.kg"  target="_blank"  rel="noopener noreferrer">
            <img src={ImageRec2} alt="" className='footer-Img' />
          </a>
        </div>
        <p className='footer-block-text' id='Info'>© 2012-2024 Курьерская служба доставки "Zakaz.KG"</p>
      </div>
      <div className='footer-blocks'>
        <div className='footer-block2'>
          <p className='footer-block-title'>ГРАФИК РАБОТЫ</p>
          <p className='footer-block-text' id='block-text'>7 дней в неделю с 09:00 до 21:00 без выходных</p>
        </div>
        <div className='footer-block'>
          <p className='footer-block-title'>КОНТАКТЫ</p>
          <a href="tel:+99655897-30-80" className='footer-block-num'><img src={ImageFrame8} alt="" className='num'></img>+996 558 97-30-80</a>
          <a href="tel:+99677897-30-80" className='footer-block-num'><img src={ImageFrame8} alt="" className='num'></img>+996 778 97-30-80</a>
          <a href="https://wa.me/996706973080" className='footer-block-num'><img src={ImageFrame8} alt="" className='num'></img>+996 706 97-30-80</a>
          <a href="tel:+99655097-30-80" className='footer-block-num'><img src={ImageFrame8} alt="" className='num'></img>+996 550 97-30-80</a>
          <a href="tel:+99677597-30-80" className='footer-block-num'><img src={ImageFrame8} alt="" className='num'></img>+996 775 97-30-80</a>
          {/* <a href="tel:+99631297-30-80" className='footer-block-num'><img src={ImageFrame8} alt="" className='num'></img>+996 312 97-30-80</a> */}
          <p></p>
        </div>
      </div>
      <div className='footer-blocks2'>
        <div className='footer-block'>
          <p className='footer-block-glavtext'>ОсОО «Заказ Кейджи»</p>
          <div id='invis-div1'>
            <p className='footer-block-text'>Директор Юсупов Д. Р.</p>
            <p className='footer-block-text'>720004, Кыргызская Республика, г. Бишкек, ул. Раззакова 19</p>
            <p className='footer-block-text'>ИНН: 01106202110211</p>
            <p className='footer-block-text'>ОКПО: 31149954</p>
          </div>
          <label className='footer-btn' htmlFor='footer-check'>читать дальше</label>
        </div>
        <div className='footer-block'>
          <p className='footer-block-glavtext'>ОАО БАНК «Бай-Тушум»</p>
          <div id='invis-div2'>
            <p className='footer-block-text'>р/счет</p>
            <p className='footer-block-req'>KGS: 1371040008302076</p>
            <p className='footer-block-req'>USD: 1371040008302379</p>
            <p className='footer-block-req'>EUR: 1371040008302480</p>
            <p className='footer-block-req'>RUB: 1371040008302276</p>
            <p className='footer-block-req'>Тел.: +996 (700) 009-191</p>
            <p className='footer-block-req'>e-mail: zakaz@zakaz.kg</p>
          </div>
          <label className='footer-btn' htmlFor='footer-check2'>читать дальше</label>
        </div>
      </div>
    </footer>
  )
}

export default Footer