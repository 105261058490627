import React, { useState } from 'react'
import './Content.css'
import axios from 'axios'
import Swal from 'sweetalert2'

function Content({contentRef}) {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    pickup: '',
    delivery: '',
    amount: '',
    comment: ''
  })
  const [emptyFields, setEmptyFields] = useState([])
  const [focusedField, setFocusedField] = useState(null)

  const handleInputChange = (event) => {
    const { name, value } = event.target
    let updatedValue = value;
    if (name === 'amount' && !/^\d*$/.test(value)) {
      updatedValue = value.replace(/\D/g, '');
    }
    const updateFormData = {
      ...formData,
      [name]: updatedValue
    }
    const updatedEmptyFields = emptyFields.filter(field => field !== name)
    setFormData(updateFormData);
    setEmptyFields(updatedEmptyFields)
  }
  const checkEmptyFields = () => {
    const requiredFields = ['name', 'phone', 'pickup', 'delivery', 'amount']
    const emptyFields = requiredFields.filter(field => !formData[field])
    setEmptyFields(emptyFields)
    return emptyFields.length === 0;
  }
  const handleSubmit = () => {
    try {
      const check = checkEmptyFields()
      if (check) {
        axios.post('https://mis.kg/create_delivery', formData)
          .then(response => {
            console.log('Ответ сервера:', response);
            if (response.status === 200 && response.data.result === 1) {
              Swal.fire({
                title: "Успешно!",
                text: "Ваш заказ успешно оформлен! С вами свяжется оператор.",
                icon: "success",
                confirmButtonColor: '#007F95'
              }).then((result) => {
                if (result.isConfirmed) {
                  window.location.reload();
                } 
              });
              console.log('Успешный ответ от сервера');
            } else {
              console.error('Не удалось создать доставку');
            }
          })
          .catch(error => {
            console.error('Ошибка при отправке запроса:', error);
          });
      } else {
        console.log(formData)
      }
    } catch (error) {
      console.error('Произошла ошибка:', error);
    }
  }
  const handleFocus = (event) => {
    const { name } = event.target
    setFocusedField(name)
    const input = document.querySelector(`[name="${name}"]`);
    input.classList.add('focused-input');
  }
  const handleBlur = (event) => {
    const { name, value } = event.target
    setFocusedField(null);
    const input = document.querySelector(`[name="${name}"]`);
    if (value.trim() === '') {
      input.classList.remove('focused-input');
    } else {
      input.classList.add('focused-input');
    }
  }
  return (
    <div className='Content' ref={contentRef}>
      <article className='Content-Title'>
        <h1 className='Title'>Zakaz.<span className='Title-span' id='red'>KG</span> — экспресс доставка в течении 90 минут!</h1>
        <p className='Title-text'>С 2012 года компания «Zakaz.KG» успешно предоставляет
          услуги срочной курьерской доставки как для физических, так и для юридических лиц, а также является надежным
          логистическим партнером по услугам доставки для интернет магазинов, кафе и ресторанов!
        </p>
        <p className='Title-text' id='Text'>Но при этом мы не ограничиваем спектр курьерских услуг и без проблем можем купить и привезти товары и продукты из магазина, организовать доставку для онлайн магазина, офлайн магазина, кафе, ресторана или любого другого
          бизнеса.
        </p>
      </article>
      <article className='Order-registration'>
        <h1 className='Order-registration-title'>Оформление заказа</h1>
        <div className='block-1'>
          <div className='Order-registration-field'>
            <p className='Order-registration-field-text'>Имя</p>
            <input type="text" placeholder='Александр(а)' className={emptyFields.includes('name') ? 'Order-registration-input empty-field' : 'Order-registration-input'} name='name' value={formData.name} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
          </div>
          <div className='Order-registration-field'>
            <p className='Order-registration-field-text'>Телефон</p>
            <input type="text" placeholder='0700123456' className={emptyFields.includes('phone') ? 'Order-registration-input empty-field' : 'Order-registration-input'} name='phone' value={formData.phone} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
          </div>
          <div className='Order-registration-field'>
            <p className='Order-registration-field-text'>Откуда забрать</p>
            <input type="text" placeholder='Пункт' className={emptyFields.includes('pickup') ? 'Order-registration-input empty-field' : 'Order-registration-input'} name='pickup' value={formData.pickup} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
          </div>
          <div className='Order-registration-field'>
            <p className='Order-registration-field-text'>Куда доставить</p>
            <input type="text" placeholder='Пункт' className={emptyFields.includes('delivery') ? 'Order-registration-input empty-field' : 'Order-registration-input'} name='delivery' value={formData.delivery} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
          </div>
        </div>
        <div className='block-2'>
          <div className='Order-registration-field'>
            <p className='Order-registration-field-text'>Сумма выкупа</p>
            <input type="text" placeholder='1000' className={emptyFields.includes('amount') ? 'Order-registration-input2 empty-field' : 'Order-registration-input2'} name='amount' value={formData.amount} onChange={handleInputChange} pattern='[0-9*]' onFocus={handleFocus} onBlur={handleBlur} />
          </div>
          <div className='Order-registration-field'>
            <p className='Order-registration-field-text'>Комментарий</p>
            <textarea placeholder='Забрать посылку' className='Order-registration-input2' id='unic-input' name='comment' value={formData.comment} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur}></textarea>
          </div>
        </div>
        <div className='block-3'>
          <button onClick={handleSubmit}>Оформить</button>
          <button>Оплатить СБП</button>
        </div>
      </article>
    </div>
  )
}

export default Content