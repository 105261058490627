import React from 'react'
import './TariffsAndServices.css'
import { Swiper, SwiperSlide } from "swiper/react";
import {Autoplay} from 'swiper/modules';
import "swiper/css";

function TariffsAndServices({tarifServRef}) {
  return (
    <article className='Tariffs_and_services' ref={tarifServRef}> 
            <h1 className='Tariffs_and_services-title'>Тарифы и услуги</h1>
            <p className='Tariffs_and_services-bigtext'>Самые выгодные тарифы по г. Бишкек!</p>
            <ul className='Tariffs_and_services-text'>
              <li>Вы всегда можете заказать курьера не опасаясь за свой товар.</li>
              <li>При надобности выкупа(продуктов, одежды, посылок и тд.) наша служба всегда в помощь!</li>
              <li>Доставим любой груз в любую точку г. Бишкек.</li>
              <li>Вы всегда можете заказать личного курьера на час или на день.</li>
              <li>Доставим в самый быстрый срок. (от 60 минут до 180 минут)</li>
            </ul>
            <div className='Tariffs_and_services-block'>
            <div className='Tariffs_and_services-blocks'>
               <ul className='Tariffs_and_services-list'>
                <button className='Tariffs_and_services-btn'>Тарифы</button>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка в черте города</li>
                <li className='Tariffs_and_services-block-text'>Обычная доставка (Пешая)</li>
                <li className='Tariffs_and_services-block-text'>Грузоперевозки</li>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка за черту города</li>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка из аптек</li>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка продуктов питания</li>
                <li className='Tariffs_and_services-block-text'>Личный курьер</li>
               </ul>
              </div>
              <div className='Tariffs_and_services-blocks'>
                <ul className='Tariffs_and_services-list'>
                 <button className='Tariffs_and_services-btn'>Описание</button>
                  <li className='Tariffs_and_services-block-text'>от 90 мин до 180 минут Максимальный вес до 15 кг</li>
                  <li className='Tariffs_and_services-block-text'>от 150 мин до 300 минут Максимальный вес до 5 кг</li>
                  <li className='Tariffs_and_services-block-text'>от 100 мин до 200 минут Максимальный вес до 1.5 тонн</li>
                  <li className='Tariffs_and_services-block-text'>от 120 мин до 240 минут</li>
                  <li className='Tariffs_and_services-block-text'>В течении 60 - 90 минут</li>
                  <li className='Tariffs_and_services-block-text'>от 120 мин до 180 минут Доставим продукты питания из любого гипермаркета г. Бишкек</li>
                  <li className='Tariffs_and_services-block-text'>от 3-х часов до дня</li>
                </ul>
               </div>
               <div className='Tariffs_and_services-blocks'>
                 <ul className='Tariffs_and_services-list'>
                  <button className='Tariffs_and_services-btn'>Стоимость курьерской услуги</button>
                  <li className='Tariffs_and_services-block-text'>от 200 до 250 сом Стоимость зависит от расстояния точки А до точки Б(уточняйте у оператора)</li>
                  <li className='Tariffs_and_services-block-text'>от 150 сом Стоимость зависит от расстояния точки А до точки Б  (уточняйте у оператора)</li>
                  <li className='Tariffs_and_services-block-text'>От 700 сом(за 1 час)</li>
                  <li className='Tariffs_and_services-block-text'>от 250 до 400 сом Стоимость зависит от расстояния точки А до точки Б (уточняйте у оператора)</li>
                  <li className='Tariffs_and_services-block-text'> от 200 сом</li>
                  <li className='Tariffs_and_services-block-text'>от 200 сом до 350 сом Стоимость зависит от веса и количества продуктов питания</li>
                  <li className='Tariffs_and_services-block-text'>500 сом(за 1 час)</li>
                 </ul>
                 </div>
            </div>
            <div className='Swiper'>
             <Swiper
             autoplay={{
              delay: 6000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
             className="mySwiper2">
              <SwiperSlide>
              <div className='Tariffs_and_services-blocks'>
               <ul className='Tariffs_and_services-list'>
                <button className='Tariffs_and_services-btn'>Тарифы</button>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка в черте города</li>
                <li className='Tariffs_and_services-block-text'>Обычная доставка (Пешая)</li>
                <li className='Tariffs_and_services-block-text'>Грузоперевозки</li>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка за черту города</li>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка из аптек</li>
                <li className='Tariffs_and_services-block-text'>Экспресс доставка продуктов питания</li>
                <li className='Tariffs_and_services-block-text'>Личный курьер</li>
               </ul>
              </div>
              </SwiperSlide>
               <SwiperSlide>
               <div className='Tariffs_and_services-blocks'>
                <ul className='Tariffs_and_services-list'>
                 <button className='Tariffs_and_services-btn'>Описание</button>
                  <li className='Tariffs_and_services-block-text'>от 90 мин до 180 минут Максимальный вес до 15 кг</li>
                  <li className='Tariffs_and_services-block-text'>от 150 мин до 300 минут Максимальный вес до 5 кг</li>
                  <li className='Tariffs_and_services-block-text'>от 100 мин до 200 минут Максимальный вес до 1.5 тонн</li>
                  <li className='Tariffs_and_services-block-text'>от 120 мин до 240 минут</li>
                  <li className='Tariffs_and_services-block-text'>В течении 60 - 90 минут</li>
                  <li className='Tariffs_and_services-block-text'>от 120 мин до 180 минут Доставим продукты питания из любого гипермаркета г. Бишкек</li>
                  <li className='Tariffs_and_services-block-text'>от 3-х часов до дня</li>
                </ul>
               </div>
               </SwiperSlide>
                <SwiperSlide>
                <div className='Tariffs_and_services-blocks'>
                 <ul className='Tariffs_and_services-list'>
                  <button className='Tariffs_and_services-btn'>Стоимость курьерской услуги</button>
                  <li className='Tariffs_and_services-block-text'>от 200 до 250 сом Стоимость зависит от расстояния точки А до точки Б(уточняйте у оператора)</li>
                  <li className='Tariffs_and_services-block-text'>от 150 сом Стоимость зависит от расстояния точки А до точки Б  (уточняйте у оператора)</li>
                  <li className='Tariffs_and_services-block-text'>От 700 сом(за 1 час)</li>
                  <li className='Tariffs_and_services-block-text'>от 250 до 400 сом Стоимость зависит от расстояния точки А до точки Б (уточняйте у оператора)</li>
                  <li className='Tariffs_and_services-block-text'> от 200 сом</li>
                  <li className='Tariffs_and_services-block-text'>от 200 сом до 350 сом Стоимость зависит от веса и количества продуктов питания</li>
                  <li className='Tariffs_and_services-block-text'>500 сом(за 1 час)</li>
                 </ul>
                 </div>
                </SwiperSlide>
             </Swiper>
             </div>
           </article>
  )
}

export default TariffsAndServices