import React from 'react'
// import { useState } from 'react';
import './Contentinfo.css'
import ImageBefore3 from '../../assets/Images/__before(3).png'
import ImageBefore5 from '../../assets/Images/__before(5).png'
import ImageBefore4 from '../../assets/Images/__before(4).png'
import ImageBefore6 from '../../assets/Images/__before(6).png'
import ImageCardIcon from '../../assets/Images/card-icon.svgfill(1).png'
import ImageSmsIcon from '../../assets/Images/sms-icon.svgfill(1).png'

function Contentinfo({contentInfoRef}) {


  return (
    <article className='Content-info' ref={contentInfoRef}>
      <input type='checkbox' id='check'/>
        <h1 className='Info-title'>Zakaz.KG — это новый прорыв на рынке срочной курьерской доставки</h1>
        <div className='info-blocks'>
         <div className='info-block1'>
          <div className='info-div'>
           <img src={ImageBefore3} alt="" className="Icons"/>
           <p className='info-div-text'>Оператор сразу сообщит стоимость доставки от точки А до точки Б.</p>
          </div>
           <div className='info-div'>
            <img src={ImageBefore5} alt="" className="Icons"/>
            <p className='info-div-text'>В течении 8 минут назначим самого ближайшего курьера.</p>
           </div>
          <div className='info-div'>
           <img src={ImageBefore4} alt="" className="Icons"/>
           <p className='info-div-text'>Оформить заказ на курьерскую или грузовую доставку можно без регистрации и договора.</p>
          </div>
         </div>
          <div className='info-block2'>
          <div className='info-div'>
           <img src={ImageBefore6} alt="" className="Icons"/>
            <p className='info-div-text'>Вы можете сразу указать откуда забрать и куда нужно будет доставить вашу доставку.</p>
          </div>
           <div className='info-div'>
            <img src={ImageCardIcon} alt="" className="Icons"/>
            <p className='info-div-text'>Оплачивать доставку можно на любом из адресов, за наличный расчет и за безналичный расчет.</p>
           </div>
            <div className='info-div'>
             <img src={ImageSmsIcon} alt="" className="Icons"/>
             <p className='info-div-text'>Оповещение после выполнение заказа.</p>
            </div>
         </div>
        <label className='Btn' htmlFor="check">Узнать больше</label>
        </div>
      </article>
  )
}

export default Contentinfo