import React from 'react'
import './DeliveryAndPayment.css'
import ImageFrame from '../../assets/Images/Frame.png'
import ImageFrame1 from '../../assets/Images/Frame(1).png'

function DeliveryAndPayment({deliverPayRef}) {
  return (
    <article className='Delivery_and_payment-block' ref={deliverPayRef}>
      <input type="checkbox" id="isexpanded"/>
      <input type="checkbox" id="isexpanded2"/>
           <h1 className='Delivery_and_payment-title'>Доставка и оплата</h1>
           <div className='Delivery_and_payment'>
           <div className='Delivery'>
            <div className='Delivery-header'>
             <img src={ImageFrame} alt="" className='DAP-Img'/>
             <p className='Delivery-title'>Доставка</p>
            </div>
            <div className='Delivery-content'>
             <p className='Delivery-text'>Доставка оформляется сразу после обращение в нашу службу и выполняться точно в срок по выбранному тарифному плану.</p>
             <div className='Delivery-text2'>
              <p className='Delivery-text'>Доставка может быть выполнена с опозданием во время час пиков в городе.</p>
              <p className='Delivery-text'>Наша служба доставляет по всему городу Бишкек и пригороду, а так-же отправка по Чуйской области</p>
             </div>
             <label className='DAP-Btn' htmlFor="isexpanded">читать дальше</label>
            </div>
           </div>
           <div className='Payment'>
            <div className='Payment-header'>
             <img src={ImageFrame1} alt="" className='DAP-Img'/>
             <p className='Payment-title'>Оплата</p>
            </div>
           <div className='Payment-content'>
             <ol className='Payment-text'>
             <p id='Payment-p'>Оплату можно произвести 4-мя способами:</p>
              <li>Оплата наличными курьеру при получении товара.</li>
              <div className='Payment-text2'>
               <li>Оплата электронным платежом, через электронный кошелек "MBank”</li>
               <li>Оплату мобильным платежом, через мобильный кошелек "Balance.KG"<a href="https://balance.kg/" className='Payment-link'>www.balance.kg</a></li>
               <li>Оплата через "Яндекс деньги"<a href="https://www.money.yandex.ru/" className='Payment-link'> www.money.yandex.ru</a></li>
              </div>
              <label className='DAP-Btn' htmlFor="isexpanded2">читать дальше</label>
             </ol>
            </div>
           </div>
           </div>
          </article>
  )
}

export default DeliveryAndPayment