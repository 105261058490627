import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';
import ImageFrame5 from '../../assets/Images/Frame(5).png'
import ImageGroup4 from '../../assets/Images/Group(4).png'

function Header({deliverPayRef, tarifServRef, storesOrgRef, contactRef}) {
  const handleScroll = (ref) => {
    console.log(ref, 'llll')
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  
  return (
    <header>
      <img src={ImageFrame5} alt="" className='logo' />
      <div className='Links'>
        <img src={ImageGroup4} alt="" className='Whatsappicon' />
        <input id="menu-toggle" type="checkbox" />
        <label className='menu-button-container' htmlFor="menu-toggle">
          <div className='menu-button'></div>
        </label>
        <nav>
          <NavLink className='link' to="/stat/dostavka-i-oplata" onClick={() => {handleScroll(deliverPayRef.current)}}>Доставка и оплата</NavLink>
          <NavLink className='link' to="/stat/tarify-i-uslugi" onClick={() => {handleScroll(tarifServRef.current)}}>Тарифы и услуги</NavLink>
          <NavLink className='link' to="/stat/magazinam" onClick={() => {handleScroll(storesOrgRef.current)}}>Магазинам</NavLink>
          <NavLink className='link' to="/stat/organizaciyam" onClick={() => {handleScroll(storesOrgRef.current)}}>Организациям</NavLink>
          <NavLink className='link' to="/stat/kontakty" onClick={() => {handleScroll(contactRef.current)}}>Контакты</NavLink>
        </nav>
      </div>
    </header>
  );
}

export default Header;
