import './assets/Fonts/Nunito-Bold.ttf';
import './assets/Fonts/Nunito-ExtraBoldItalic.ttf';
import './assets/Fonts/Nunito-Medium.ttf';
import './assets/Fonts/Nunito-Regular.ttf';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Content from './Components/Content/Content';
import Contentinfo from './Components/Content-info/Contentinfo';
import Contentblock from './Components/Contentblock/Contentblock';
import Advantages from './Components/Advantages/Advantages';
import DeliveryAndPayment from './Components/DeliveryAndPayment/DeliveryAndPayment';
import TariffsAndServices from './Components/TariffsAndServices/TariffsAndServices';
import StoresAndOrg from './Components/StoresAndOrg/StoresAndOrg';
import Coop from './Components/Coop/Coop';
import Footer from './Components/Footer/Footer'
import { useRef } from 'react';

function App() {
  const deliverPayRef = useRef(null)
  const tarifServRef = useRef(null)
  const storesOrgRef = useRef(null)
  const contactRef = useRef(null)

  return (
    <div className="App">
      <div className='Project'>
        <Header deliverPayRef={deliverPayRef} tarifServRef={tarifServRef} storesOrgRef={storesOrgRef} contactRef={contactRef} />
        <Routes>
          <Route
            element={<>
              <Content/>
              <Contentinfo/>
              <Contentblock />
              <Advantages />
              <DeliveryAndPayment deliverPayRef={deliverPayRef} />
              <TariffsAndServices tarifServRef={tarifServRef} />
              <StoresAndOrg storesOrgRef={storesOrgRef} />
              <Coop />
              <Footer contactRef={contactRef} />
            </>}
          />
        </Routes>
        <Content />
        <Contentinfo />
        <Contentblock />
        <Advantages />
        <DeliveryAndPayment deliverPayRef={deliverPayRef}/>
        <TariffsAndServices tarifServRef={tarifServRef}/>
        <StoresAndOrg storesOrgRef={storesOrgRef} />
        <Coop />
        <Footer  contactRef={contactRef}/>
      </div>
    </div >
  );
}

export default App;
