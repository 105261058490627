import React from 'react'
import './StoresAndOrg.css'
import ImageFrame3 from  '../../assets/Images/Frame(3).png'
import ImageFrame4 from  '../../assets/Images/Frame(4).png'

function StoresAndOrg({storesOrgRef}) {
  return (
    <article className='For_stores_and_organizations' ref={storesOrgRef}>
           <h1 className='Fsao-title'>Магазинам и организациям</h1>
           <ul className='Fsao-text'>
            <li>Доставка в течении 90 минут</li>
            <li>Доставка к точному времени</li>
            <li>Доставка на следующий день</li>
            <li>Доставка на конкретную дату</li>
            <li>Доставка в выходные или ночью</li>
            <li>Возврат средств в случае утери отправления</li>
            <li>Доставить документы, полиграфию, цветы, подарки, товары и прочее.</li>
           </ul>
           <ul className='Fsao-text2'>
             <li>Вы всегда можете заказать курьера не опасаясь за свой товар.</li>
             <li>Требуется выкуп разного рода товара (выкуп товара из магазина, покупка продуктов питания, покупка детского питания и.т.д.) то наша служба всегда в помощь!</li>
             <li>Доставим любой малогабаритный груз в любую точку г. Бишкек.</li>
             <li>Вы всегда можете заказать личного курьера на час или на день.</li>
             <li>Доставим в самый быстрый срок. (от 60 минут до 180 минут)</li>
            </ul>
            <p className='Fsao-endtext'>Для сотрудничество с нашей службой вам достаточно нам написать или позвонить, мы будем рады ответить на все ваши интересующиеся вопросы. </p>
            <div className='Fsao-content'>
              <div className='Fsao-info'>
              <img src={ImageFrame3} alt="" className='Fsao-Img'/>
               <div className='Fsao-infoblock'>
                <h1 className='Fsao-infotitle'>Магазинам</h1>
                <p className='Fsao-infotext'>Наша служба доставки Zakaz.KG, работает на рынке Кыргызстана больше 4-х лет, мы закрепили свои позиции как самый надежный партнер по доставке товаров с магазинов, бутиков, кафе, ресторанов и многих других мест. </p>
               </div>
              </div>
              <div className='Fsao-info'>
              <img src={ImageFrame4} alt="" className='Fsao-Img'/>
               <div className='Fsao-infoblock'>
                <h1 className='Fsao-infotitle'>Организациям</h1>
                <p className='Fsao-infotext'>Юридическим лицам Наша служба работает по безналичном (перечислению) и наличному расчету. </p>
                <p className='Fsao-infotext' id='info-text'>За подробной информацией обращаться по нашим контактным номерам </p>
                <a href="tel:+99655897-30-80" className='Fsao-num'><img src={ImageFrame4} alt="" id='Fsao-num'></img>+996 558 97-30-80</a>
               </div>
              </div>
            </div>
            <p className='Fsao-endtext2'>Для сотрудничества с нашей службой Вам достаточно написать или позвонить нам. Мы будем рады ответить на все, интересующие Вас, вопросы!</p>
            <p className='Fsao-endtext' id='Big-text'>Также вся актуальная информация дана в нашем Инстаграм странице <a href="https://zakaz.kg/">@zakaz.kg</a></p>
          </article>
  )
}

export default StoresAndOrg